<template>
    <PageLayout>
        <template>
            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center flex-grow-1">
                    <h1>
                        <template>
                            {{ $t('company') }}
                        </template>
                    </h1>
                </div>
            </div>
            <template  v-if="$vuetify.breakpoint.smAndDown">
                <ListItem
                    v-for="item in desserts_mob"
                    :item="item"
                    :key="item.id"
                >


                </ListItem>
            </template>
            <template v-else>
                <div>
                    <v-row >
                        <v-col cols="12" class="px-0 mx-0">
                            <v-card flat class="px-0 mx-0 ">
                                <v-card-text class="py-0 my-0 px-0 mx-0">
                                    <v-data-table
                                        :headers="headers"
                                        :items="desserts"
                                        :items-per-page="55"

                                        hide-default-header
                                        hide-default-footer
                                        :item-class="rowClass"
                                        :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                        class="rounded-lg tableCompanyBorder"
                                    >

                                        <template v-slot:item.name="{ item }">
                                            <div class="col_name" v-if="item.name.title">
                                                {{ item.name.title }}
                                                <v-tooltip bottom max-width="300" color="#242424">
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon
                                                            small
                                                            v-on="on"
                                                            class="mr-2 cursor-pointer">
                                                            mdi-message-question
                                                        </v-icon>
                                                    </template>
                                                    <span >{{ item.name.tooltip }}</span>
                                                </v-tooltip>
                                            </div>
                                            <div class="company_table_header" v-if="!item.name.title">
                                                {{ item.name }}
                                            </div>
                                        </template>
                                        <template v-slot:item.category="{ item }">

                                            <div class="col_name" v-if="item.category && item.category.copy" >
                                                {{ item.category.title }}

                                                <v-icon

                                                    color="#B3B3B3"
                                                    small
                                                    :title="$t('copy')"
                                                    @click="copyText(item.category.title)"
                                                    class="mr-2 cursor-pointer">
                                                    mdi-content-copy
                                                </v-icon>

                                            </div>
                                            <div class="col_name" v-if="item.category && !item.category.copy" >
                                                {{ item.category }}
                                            </div>
                                        </template>

                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </template>
        </template>

        <template v-slot:side>
            <div></div>
        </template>
    </PageLayout>


</template>
<script>


    import {mapGetters} from "vuex"

    import PageLayout from "@/components/Leentech/PageLayout.vue";
    import ListItem from "@/components/Company/ListItem.vue";


    export default {
        name: 'Company',
        components: {
            PageLayout,
            ListItem
        },
        inject: ['forceRerender'],
        data() {
            return {
                filter_show: false,
                entityName: 'company',
                headers: [
                    {
                        text: null,
                        align: 'start',
                        value: 'name',
                        sortable: false,
                    },
                    {
                        text: null,
                        value: 'category',
                        sortable: false,
                    },
                ],
                desserts: [],
                desserts_mob: [],

                heading: null,
                progress: 0,
                tab: 0,

                logo: null,
                loading: false,


            }
        },
        computed: {
            ...mapGetters(['listLanguages', 'lang']),
            languages() {
                return this.listLanguages
            },
        },
        mounted() {
            this.language = this.languages[this.tab]
            this.init();
        },
        methods: {
            async init() {
                await this.getCompany()
            },
            copyText(text) {
                navigator.clipboard.writeText(text);
            },
            rowClass(){
                const rowClass = (this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.smOnly) ? 'rowMobiCompany' : 'rowCompany'
                return rowClass;
            },
            async setLanguage(val) {
                this.language = this.languages[val]
                await this.getCompany()
            },



            async getCompany() {
                var _this = this;
                this.progress = 0
                this.loading = true
                let params = {};
                if (this.languages && this.languages[this.tab]) {
                    params.language = this.languages[this.tab]
                }
                await this.$http
                    .get(`client/uved`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {

                        this.desserts_mob = [res.body.data]
                        this.desserts  =
                        [
                            {
                                name: this.$t('company_name'),
                                category: {
                                    'title': res.body.data.name,
                                    'copy': 1,
                                }
                            },
                            {

                                name: this.$t('company_name_legal'),
                                // name: {
                                //     'title': this.$t('company_name_legal'),
                                //     'tooltip': 'UEN stands for Unique Entity Number. It is a standard identification number for entities to interact with government agencies.',
                                // } ,
                                category: res.body.data.name_legal
                            },
                            {
                                name: this.$t('property'),
                                category: res.body.data.form_property_name
                            },
                            {
                                name: this.$t('name_director'),
                                category: res.body.data.name_director
                            },
                            {
                                name: this.$t('address_actually'),
                                category: res.body.data.address_actually
                            },
                            {
                                name: this.$t('address_legal'),
                                category: {
                                    'title': res.body.data.address_legal,
                                    'copy': 1,
                                }
                            },
                            // {
                            //     name: this.$t('contract_number'),
                            //     category: res.body.data.contract_number
                            // },
                            {
                                name: this.$t('phone'),
                                category:  {
                                    'title': res.body.data.phone,
                                    'copy': 1,
                                }
                            },
                            // {
                            //     name: this.$t('checking_account'),
                            //     category: res.body.data.checking_account
                            // },
                            // {
                            //     name: this.$t('additional_phone'),
                            //     category: res.body.data.phone_additional
                            // },
                            {
                                name: this.$t('bin_iin'),
                                category: {
                                     'title': res.body.data.bin_iin,
                                     'copy': 1,
                                }
                            },
                            {
                                name: this.$t('email'),
                                category: {
                                    'title': res.body.data.email,
                                    'copy': 1,
                                }
                            },
                            {
                                name: this.$t('date_contract'),
                                category: res.body.data.date_contract
                            },
                        ]

                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_company'));
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },

        }
    }
</script>
