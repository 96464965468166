<script>


export default {
    name: "CompanyListItem",


    props: {
        item: {
            type: Object,
            required: true
        },

    },



}
</script>

<template>
    <div class="item pt-5">

        <div class="item__data" >
            <div class="text-info" >
                <div class="text-info__label">
                    <div>{{$t('company_name')}}</div>
                </div>
                <div class="text-info__value">
                    {{ item.name }}
                </div>
            </div>
            <div class="text-info" v-if="item.name_legal" >
                <div class="text-info__label">
                    <div>{{$t('company_name_legal')}}</div>
                </div>
                <div class="text-info__value">
                    {{ item.name_legal }}
                </div>
            </div>
            <div class="text-info" v-if="item.form_property_name" >
                <div class="text-info__label">
                    <div>{{$t('property')}}</div>
                </div>
                <div class="text-info__value">
                    {{ item.form_property_name }}
                </div>
            </div>
            <div class="text-info" v-if="item.name_director" >
                <div class="text-info__label">
                    <div>{{$t('name_director')}}</div>
                </div>
                <div class="text-info__value">
                    {{ item.name_director }}
                </div>
            </div>
            <div class="text-info" v-if="item.address_actually" >
                <div class="text-info__label">
                    <div>{{$t('address_actually')}}</div>
                </div>
                <div class="text-info__value">
                    {{ item.address_actually }}
                </div>
            </div>
            <div class="text-info" v-if="item.address_legal" >
                <div class="text-info__label">
                    <div>{{$t('address_legal')}}</div>
                </div>
                <div class="text-info__value">
                    {{ item.address_legal }}
                </div>
            </div>
            <div class="text-info" v-if="item.phone" >
                <div class="text-info__label">
                    <div>{{$t('phone')}}</div>
                </div>
                <div class="text-info__value">
                    {{ item.phone }}
                </div>
            </div>
            <div class="text-info" v-if="item.bin_iin" >
                <div class="text-info__label">
                    <div>{{$t('bin_iin')}}</div>
                </div>
                <div class="text-info__value">
                    {{ item.bin_iin }}
                </div>
            </div>
            <div class="text-info" v-if="item.email" >
                <div class="text-info__label">
                    <div>{{$t('email')}}</div>
                </div>
                <div class="text-info__value">
                    {{ item.email }}
                </div>
            </div>
            <div class="text-info" v-if="item.date_contract" >
                <div class="text-info__label">
                    <div>{{$t('date_contract')}}</div>
                </div>
                <div class="text-info__value">
                    {{ item.date_contract }}
                </div>
            </div>



        </div>


        <div class="item__footer">
            <div class="text-info" >
                <div class="text-info__label">
                    <div>{{$t('date_created')}}</div>
                </div>
                <div class="text-info__value">
                    {{$moment(item.created_at).format('DD.MM.YYYY') }}
                </div>
            </div>
        </div>
    </div>
</template>
